// extracted by mini-css-extract-plugin
export var circSupply = "tokenomics-module--circ-supply--tht5w";
export var circSupplyImg = "tokenomics-module--circ-supply-img--jxlTn";
export var circSupplyInfo = "tokenomics-module--circ-supply-info--mmNCB";
export var col = "tokenomics-module--col--TfFt5";
export var development = "tokenomics-module--development--l+8ZO";
export var footer = "tokenomics-module--footer--Jmzc7";
export var funds = "tokenomics-module--funds--5Xn6D";
export var fundsImg = "tokenomics-module--funds-img--daYa2";
export var fundsInfo = "tokenomics-module--funds-info--mfSuw";
export var legal = "tokenomics-module--legal--AReWh";
export var marketing = "tokenomics-module--marketing--5YRDF";
export var operationalCost = "tokenomics-module--operational-cost--n3Iw9";
export var startup = "tokenomics-module--startup--slAmi";
export var subjectPoint = "tokenomics-module--subject-point--Qs9Yh";
export var subjectPointImg = "tokenomics-module--subject-point-img--Ii1hK";
export var subjectPointIndex = "tokenomics-module--subject-point-index--MWLo6";
export var subjectPointIndexContainer = "tokenomics-module--subject-point-index-container--jCmI+";
export var subjectPointInfo = "tokenomics-module--subject-point-info--iwqtv";
export var tokenomics = "tokenomics-module--tokenomics--BIJa-";
export var tokenomicsPage = "tokenomics-module--tokenomics-page--U+XEH";
export var totalSupply = "tokenomics-module--total-supply--c3tLL";
export var totalSupplyDistribution = "tokenomics-module--total-supply-distribution--879cu";
export var totalSupplyDistributionImg = "tokenomics-module--total-supply-distribution-img--dXOHb";
export var totalSupplyImg = "tokenomics-module--total-supply-img--5tZLY";